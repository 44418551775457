// @flow
/* eslint-disable global-require */
import 'babel-polyfill';
import 'react-app-polyfill/ie11';

import firebase from 'firebase/app';
import React from 'react';
import ReactDOM from 'react-dom';
import { AppContainer } from 'react-hot-loader';

import { APP_CONTAINER_SELECTOR } from 'common/constants';
import store, { history } from 'client/store';
import Root from './components/Root';
import firebaseConfig from '../../../firebase.json';

firebase.initializeApp(firebaseConfig);

const container: ?HTMLElement = document.querySelector(APP_CONTAINER_SELECTOR);

if (container) {
	const render = Component => {
		ReactDOM.render(
			<AppContainer>
				<Component store={store} history={history} />
			</AppContainer>,
			container
		);
	};

	render(Root);

	// flow-disable-next-line
	if (module.hot) {
		// flow-disable-next-line
		module.hot.accept('./components/Root', () => render(require('./components/Root').default));
	}
}
