// @flow
import produce from 'immer';

import * as actions from 'client/constants/actions';

const initialState: PopUp = {
	id: '',
	data: null,
};

type Action = { type: typeof actions.SET_POP_UP, payload: ?any };

export default function popUpReducer(state: PopUp = initialState, action: Action) {
	return produce<any>(state, (draft: PopUp) => {
		switch (action.type) {
			case actions.SET_POP_UP:
				return action.payload;

			default:
				break;
		}
	});
}
