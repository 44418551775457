// @flow
import * as React from 'react';
import { CSSTransition } from 'react-transition-group';

type TransitionState = 'enter' | 'entering' | 'entered' | 'exit' | 'exiting' | 'exited' | 'none';

export type EnhancedProps = {|
	in: boolean,
	transitionState: TransitionState,
	onTransitionComplete: Function,
|};

type State = {|
	transitionState: TransitionState,
|};

export function withTransition(Component: React.AbstractComponent<any>) {
	type Props = {
		in?: boolean,
		appear?: boolean,
		enter?: boolean,
		exit?: boolean,
		unmountOnExit?: boolean,
	};
	return class EnhancedComponent extends React.PureComponent<Props, State> {
		transitionEnd: Function;

		static defaultProps = {
			appear: true,
			enter: true,
			exit: true,
			unmountOnExit: true,
		};

		constructor(props: Props) {
			super(props);

			this.state = {
				transitionState: 'none',
			};
		}

		animate = (node: HTMLDivElement, done: Function) => {
			this.transitionEnd = done;
		};

		onTransitionComplete = () => {
			this.transitionEnd();
		};

		onEnter = () => {
			this.setState({ transitionState: 'enter' });
		};

		onEntering = () => {
			this.setState({ transitionState: 'entering' });
		};

		onEntered = () => {
			this.setState({ transitionState: 'entered' });
		};

		onExit = () => {
			this.setState({ transitionState: 'exit' });
		};

		onExiting = () => {
			this.setState({ transitionState: 'exiting' });
		};

		onExited = () => {
			this.setState({ transitionState: 'exited' });
		};

		render() {
			const { appear, enter, exit, unmountOnExit } = this.props;
			const { transitionState } = this.state;

			return (
				<CSSTransition
					in={this.props.in}
					addEndListener={this.animate}
					onEnter={this.onEnter}
					onEntering={this.onEntering}
					onEntered={this.onEntered}
					onExit={this.onExit}
					onExiting={this.onExiting}
					onExited={this.onExited}
					appear={appear}
					enter={enter}
					exit={exit}
					unmountOnExit={unmountOnExit}
				>
					<Component
						{...this.props}
						transitionState={transitionState}
						onTransitionComplete={this.onTransitionComplete}
					/>
				</CSSTransition>
			);
		}
	};
}
