import React, { createRef } from 'react';
import classNames from 'classnames';
import PT from 'prop-types';
import { noop } from 'lodash';

import { isMobileOrTablet } from '../Responsive';
import ScrollBar from './ScrollBar';
import * as css from './Scrollable.scss';

export class Scrollable extends React.Component {
	constructor(props) {
		super(props);

		this.contentWrapRef = createRef();
		this.contentRef = createRef();
		this.scrollBarRef = createRef();

		this.state = {
			refresh: 0,
		};
	}

	componentDidMount() {
		setTimeout(() => this.setState({ refresh: 1 }), 10);
	}

	get scrollTop() {
		return this.scrollBarRef.current.scrollingDistance;
	}

	get offsetHeight() {
		return this.contentWrapRef.current.offsetHeight;
	}

	scroll = params => {
		this.scrollBarRef.current.updateScrollingDistance(params.top);
	};

	getBoundingClientRect = () => {
		return this.contentWrapRef.current.getBoundingClientRect();
	};

	querySelector = selector => {
		return this.contentWrapRef.current.querySelector(selector);
	};

	querySelectorAll = selector => {
		return this.contentWrapRef.current.querySelectorAll(selector);
	};

	update = () => {
		this.scrollBarRef.current.update();
	};

	onScroll = value => {
		const { onScroll } = this.props;
		const scrollTop = typeof value === 'number' ? value : value.currentTarget.scrollTop;

		onScroll(scrollTop);
	};

	render() {
		const { className, children } = this.props;
		const isDesktop = !isMobileOrTablet();

		return (
			<div
				className={classNames(css.scrollable, className, { [css.mobile]: !isDesktop })}
				onScroll={this.onScroll}
				ref={this.contentWrapRef}
			>
				<div className={css.scrollableContent} ref={this.contentRef}>
					{children}
				</div>
				{isDesktop && children && (
					<ScrollBar
						// eslint-disable-next-line react/no-children-prop
						children={children}
						contentWrapRef={this.contentWrapRef.current && this.contentWrapRef}
						contentRef={this.contentRef.current && this.contentRef}
						onScroll={this.onScroll}
						ref={this.scrollBarRef}
						data-refresh={this.state.refresh}
					/>
				)}
			</div>
		);
	}
}

Scrollable.defaultProps = {
	className: '',
	children: null,
	onScroll: noop,
};

Scrollable.propTypes = {
	className: PT.string,
	children: PT.oneOfType([PT.node, PT.arrayOf(PT.node)]),
	onScroll: PT.func,
};
